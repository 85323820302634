import applyProdQaConfig from "../../../utils/applyProdQaConfig";

export const eventId = applyProdQaConfig({
  prod: {
    ayd: "b8517361-f9ed-4fc1-8609-2347be87414c",
  },
  qa: {
    ayd: "e1db75e8-2e54-45fb-977a-7989e1d2f7a9",
  },
});

export const gTagId = {
  peaceDay: "AW-881209313/wLgTCM6brvUCEOHfmKQD",
  webinar: "AW-881209313/hSo0CJPGrPUCEOHfmKQD",
};

const config = {
  ref: "B99999999",
  event: eventId.ayd,
  email: null,
  name: "",
  pnr: "",
  reg_json: {},
  communication_preference: 1,
};

export default config;
