import React from "react";
import { Link } from "react-scroll";
import { Navbar, Nav } from "react-bootstrap";

const Header = () => {
  return (
    <header className="header-nav">
      <Navbar
        collapseOnSelect
        className="navbar navbar-expand-md px-md-5 fixed-top menu-bg-dark-new"
        expand="md"
        variant="dark"
        id="menu_nav_bar"
      >
        <div className="container">
          <Link
            to="gatsby-focus-wrapper"
            className="navbar-brand img-fluid"
            smooth
            duration={500}
            style={{ cursor: "pointer" }}
          >
            <svg
              fill="#ffffff"
              id="Group_1445"
              data-name="Group 1445"
              xmlns="http://www.w3.org/2000/svg"
              width="1063.919"
              height="297.869"
              viewBox="0 0 1063.919 297.869"
            >
              <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M147.8,229.52q0,1.974-4.19,1.975h-44.9c-2.141,0-3.2-.659-3.2-1.975q0-1.719,9.37-1.722,5.68,0,6.539-1.349t.866-9.228v-69.4q0-22.392-1.975-27.809-4.2-11.33-18.5-11.33-11.848,0-19.242,10.586-2.468,3.443-2.468,15.259v82.2a58.809,58.809,0,0,0,.247,7.622c.162,1.65.823,2.59,1.97,2.841a93.827,93.827,0,0,0,10.615.606c1.97,0,2.954.665,2.954,1.975q0,1.719-2.461,1.722H61.463q-20.479-.259-22.7,0H36.293c-1.806,0-2.713-.5-2.713-1.5q0-1.706,2.713-1.7H40l3.7-.247c3.781.162,5.761-.994,5.918-3.466V94.2c0-26.351-.413-47.032-1.23-49.169Q46.9,42.555,36.293,42.306c-3.944,0-5.918-.606-5.918-1.837,0-1.049.414-1.654,1.236-1.831s3.614-.35,8.39-.528a56.966,56.966,0,0,0,24.17-5.918,23.719,23.719,0,0,1,3.456-1.235q1.722,2.953,1.728,18.991v66.878q10.851-15.776,29.6-15.786,19.735,0,28.124,10.853,4.2,5.436,5.18,15.292.488,3.458.493,16.534v81.6q.244,2.481,12.826,2.471,2.222.5,2.221,1.728"
                  transform="translate(-30.375 -30.957)"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M199.554,176.087q0,2.431-3.762,8.612a53.821,53.821,0,0,1-7.636,10.053q-10.422,10.673-27.879,10.665-21.818,0-36.485-15.751t-14.667-39.036q0-24.24,13.943-40.483t34.786-16.244q17.208,0,28.849,10.065a34.855,34.855,0,0,1,12.363,25.82,125.259,125.259,0,0,1-20.605.965H161.493q-1.706,0-29.095.72a101.659,101.659,0,0,0-1.21,15.218q0,24.4,9.329,37.569t26.3,13.161q9.7,0,15.514-4.367t12.605-16.721a2.966,2.966,0,0,1,2.674-1.946c1.285,0,1.94.572,1.94,1.7m-21.093-49.453q-.969-29.093-20.848-29.09-19.639,0-24.485,29.09Z"
                  transform="translate(5.132 -2.575)"
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M270.239,188.581q0,4.128-8.562,8.611a35.609,35.609,0,0,1-16.524,4.482q-16.647,0-17.371-17.209Q218.633,202.4,199.329,202.4q-10.37,0-17.12-6.179a20.52,20.52,0,0,1-6.755-15.878q0-19.874,23.777-31.512,8.168-3.878,28.34-7.761v-1.2c0-14.22-.723-18.735-2.181-23.261q-3.878-12.37-17.45-12.367-8.494,0-14.185,4.608a14.367,14.367,0,0,0-5.7,11.64c0,1.29,1,1.93,3.032,1.93a7.044,7.044,0,0,1,5.451,2.915,9.961,9.961,0,0,1,2.421,6.544q0,10.664-10.664,10.665a12.287,12.287,0,0,1-9.212-3.752,13.214,13.214,0,0,1-3.634-9.582q0-11.4,8.971-19.632,9.933-8.973,27.632-8.975,26.669,0,32.728,14.544c1.458,3.723,2.182,6.214,2.182,17.209V172.83q0,11.152.241,13.087,1.2,8.723,7.031,8.724A14.323,14.323,0,0,0,265.39,189.8c1.609-1.777,2.826-2.664,3.639-2.664s1.21.477,1.21,1.448m-42.669-25.7v-17.7q-17.918,4.128-25.686,10.787-7.763,6.677-7.765,17.82,0,7.763,4.416,12.727a14.253,14.253,0,0,0,11.118,4.974q10.266,0,16.485-11.64,1.432-2.666,1.432-16.971"
                  transform="translate(35.038 0.446)"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M316.681,107.374a12.968,12.968,0,0,1-3.4,9.213,11.4,11.4,0,0,1-8.73,3.639,10.647,10.647,0,0,1-7.267-2.551,8.137,8.137,0,0,1-2.909-6.42,8.441,8.441,0,0,1,2.176-5.821c1.456-1.615,2.185-2.742,2.185-3.392q0-3.632-5.332-3.639-6.794,0-11.639,8.444c-3.718,6.6-5.574,23.684-5.574,41.531v47.756c0,1.935.685,3.137,2.063,3.614q2.052.74,12.482.971c1.777.329,2.668.969,2.668,1.944,0,1.127-1.053,1.695-3.151,1.695H243.223c-2.59,0-3.88-.483-3.88-1.458s1.053-1.452,3.156-1.452h3.393q8.235,0,9.695-1.954.487-.962.488-9.011V118.616q0-13.4-4-15.471t-12.729-2.314q-2.91,0-2.909-1.693c0-1.133.806-1.7,2.427-1.7h1.693q3.641,0,7.272.36a27.094,27.094,0,0,1,3.151.364q8.967,0,19.883-5.087l3.151-1.457,1.934-.728a70.367,70.367,0,0,1,.975,11.639v4.844q7.757-16.483,21.817-16.484a18.156,18.156,0,0,1,12.852,4.727,15.414,15.414,0,0,1,5.091,11.756"
                  transform="translate(62.533 -3.934)"
                />
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M358.209,202.3q0,2.185-6.061,7.755a35.548,35.548,0,0,1-24.723,9.452q-13.337,0-20.122-6.77-3.885-3.863-3.881-22.732V89.787a2.694,2.694,0,0,0-2.668-2.664h-9.211c-3.555,0-5.333-.561-5.333-1.7s.886-1.777,2.649-1.94q18.782-1.706,29.391-18.641a4.237,4.237,0,0,1,3.608-2.2,1.5,1.5,0,0,1,1.689,1.693v5.091q0,10.185.971,13.093a37.609,37.609,0,0,0,7.267.482q13.33,0,17.7-1.21a7.052,7.052,0,0,1,1.693-.247c1.289,0,1.945.7,1.945,2.092q0,3.486-19.64,3.486h-9.937v73q0,33.944,1.695,42.55t11.151,8.6q8.007,0,17.943-8.971a2.755,2.755,0,0,1,2.181-1.211q1.691,0,1.693,1.211"
                  transform="translate(84.976 -16.671)"
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M418.972,49.45a11.824,11.824,0,0,1-3.032,8.361,10.236,10.236,0,0,1-7.878,3.273,11.04,11.04,0,0,1-7.759-2.909,9.492,9.492,0,0,1-3.147-7.273q0-3.632,3.147-8.483a4.3,4.3,0,0,0,.724-2.186c0-3.707-2.645-5.573-7.924-5.573q-13.471,0-17.789,17.45-2.163,9.223-2.156,32.25V96.231h15.81c3.2,0,4.8.695,4.8,2.067s-2.088,2.059-6.239,2.059H373.158V302.59q0,3.406,6.686,3.4,10.29-.237,11.252.975a1.389,1.389,0,0,1,.236.969c0,1.127-.965,1.695-2.911,1.695a10.19,10.19,0,0,1-2.423-.242l-19.877.242H339.7c-2.9,0-4.363-.567-4.363-1.695q0-1.944,3.491-1.944,12.46,0,13.708-1.448c.329-.325.5-79.085.5-79.73l-.242,75.121V100.6q-8.973,0-10.418-.241a1.7,1.7,0,0,1-1.462-1.94c0-1.458,1.462-2.186,4.367-2.186h7.755q.975-30.784,8.247-44.358,11.144-20.849,33.447-20.852,10.673,0,17.46,5.1a15.91,15.91,0,0,1,6.788,13.334"
                  transform="translate(107.125 -30.928)"
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M490.66,193.368q0,1.7-5.332,1.94-18.908,1.206-33.211,8.238a3.918,3.918,0,0,1-1.694.488q-1.462,0-1.462-1.94v-13.58q-11.071,15.526-31.024,15.52-17.8,0-25.5-10.349a19.6,19.6,0,0,1-3.728-7.942q-.6-3.619-.6-19.5V111.107c0-5.618-.329-8.911-.969-9.872s-2.837-1.522-6.554-1.684h-3.147c-4.367.321-6.538-.319-6.538-1.944,0-1.127,1.364-1.7,4.116-1.7q4.363,0,12.6.654c2.748.218,4.359.321,4.846.321a118.131,118.131,0,0,0,15.761-.975v67.318q0,18.575,2.422,24.608,3.884,9.417,15.761,9.415,12.845,0,18.908-10.622,4.364-7.717,4.363-24.608V124.628a76.153,76.153,0,0,0-.73-12.309v-7.961c-.162-2.413-.679-3.822-1.57-4.215a15.543,15.543,0,0,0-5.456-.611q-10.186.244-10.183-2.172c0-.96,1.7-1.452,5.091-1.452h32.975v67.637q0,17.461.713,22.068t3.707,5.333q3.006.723,13.545.724c1.921,0,2.876.566,2.876,1.7"
                  transform="translate(123.166 -1.672)"
                />
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M502.782,229.171c0,1.452-1.053,2.181-3.147,2.181h-2.909c-.331,0-4.6-.163-12.852-.482h-7.513q-1.944,0-19.4.358c-.975.08-1.934.123-2.909.123-2.423.158-3.635-.517-3.635-2.043,0-1.064.883-1.6,2.664-1.6h6.307c4.515,0,6.786-.969,6.786-2.9q.245-1.456.245-18.912V54.294q0-8-.486-8.724c-.487-1.29-1.779-1.944-3.881-1.944h-8.724q-3.4,0-3.4-2.059c0-1.226,2.019-1.9,6.056-2.059a59.678,59.678,0,0,0,21.582-5.336l5.091-2.423,1.944-.728q1.928,7.038,1.689,21.335V216.562a79.134,79.134,0,0,0,.247,8.611,2.277,2.277,0,0,0,1.7,1.816c.153.162,3.152.325,8.971.487,3.717,0,5.568.567,5.568,1.695"
                  transform="translate(158.797 -30.929)"
                />
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M601.116,201.188q0,1.811-3.634,1.808H552.869c-2.1,0-3.147-.562-3.147-1.695a1.392,1.392,0,0,1,1.335-1.446c.88-.162,4-.321,9.335-.487,3.063-.158,4.845-.6,5.328-1.325s.728-3.349.728-7.864V143.747q0-26.358-1.939-31.442-4.12-10.873-16.484-10.882-12.12,0-20.364,10.641-2.424,3.145-2.423,8.7v75.456q0,2.9,5.175,3.143l7.395.245q2.459,0,2.457,1.689c0,1.133-.965,1.695-2.909,1.695H493.237c-2.423,0-3.634-.488-3.634-1.483s1.448-1.624,4.367-1.905h6.051c2.423,0,3.886-.572,4.367-1.7l.73-3.623V141.577q0-27.557-.488-30.458t-6.066-2.9h-7.026q-4.845,0-4.844-1.8,0-2.32,5.091-2.324a65.55,65.55,0,0,0,15.755-2.664q8.485-2.422,11.877-5.579c1.452-1.29,2.58-1.94,3.4-1.94,1.127,0,1.7,1.541,1.7,4.6v11.639q12.6-16.233,32.482-16.243,17.452,0,25.214,12.85,4.359,7.276,4.368,27.636v55.274c0,5.171.235,8,.723,8.485q1.457,1.46,7.027,1.457h4.367c1.615,0,2.423.533,2.423,1.58"
                  transform="translate(175.371 -2.575)"
                />
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M658.961,176.508q0,2.622-4.053,9.291a58.424,58.424,0,0,1-8.236,10.857q-11.263,11.515-30.1,11.507-23.539,0-39.375-17-15.828-17.017-15.825-42.132,0-26.159,15.041-43.693t37.549-17.528q18.575,0,31.133,10.855t13.348,27.869a134.311,134.311,0,0,1-22.245,1.045H617.882q-1.823,0-31.4.778a109.664,109.664,0,0,0-1.308,16.425q0,26.329,10.078,40.546,10.068,14.2,28.391,14.206,10.466,0,16.74-4.712,6.294-4.7,13.609-18.05a3.2,3.2,0,0,1,2.876-2.1c1.394,0,2.093.621,2.093,1.837M636.2,123.135q-1.05-31.4-22.5-31.4-21.2,0-26.431,31.4Z"
                  transform="translate(209.042 -5.322)"
                />
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M716.771,173.193a38.546,38.546,0,0,1-12.344,28.864,41.037,41.037,0,0,1-29.468,11.871q-9.876,0-24.1-7.012a14.478,14.478,0,0,0-6.681-2.049c-2.724,0-4.747,1.857-6.1,5.555q-1.176,3.508-3.2,3.505c-1.358,0-2.033-.778-2.033-2.335V172.411q0-3.221,2.048-3.221c1.552,0,2.432,1.088,2.63,3.24q1.758,13.561,7.607,21.208,11.691,15.326,31.281,15.327,10.531,0,16.957-6.155t6.437-16.415q0-11.426-7.313-18.169a31.543,31.543,0,0,0-7.455-5.426q-3.361-1.608-19.729-7.764-32.466-12.311-32.462-36.928,0-16.412,11.024-27.549t27.268-11.137q5.8,0,17.4,3.505a18.1,18.1,0,0,0,5.225.882q6.093,0,7.537-5.846c.586-1.944,1.454-2.929,2.614-2.929,1.359,0,2.034.788,2.034,2.345V91.42q-.295,7.016,1.458,22.506v.586a2.066,2.066,0,0,1-2.339,2.339q-1.758,0-4.383-8.848a34.1,34.1,0,0,0-11.264-17.115,28.7,28.7,0,0,0-18.563-6.5q-9.949,0-16.377,6.155a20.63,20.63,0,0,0-6.434,15.53q0,14.653,19.59,22.275,30.415,12.031,38.6,19.931a33.489,33.489,0,0,1,10.527,24.909"
                  transform="translate(241.27 -11.084)"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M789.569,173.446a42.541,42.541,0,0,1-13.63,31.861,45.3,45.3,0,0,1-32.526,13.1q-10.9,0-26.6-7.739a16,16,0,0,0-7.376-2.256q-4.482,0-6.73,6.13c-.856,2.581-2.033,3.865-3.526,3.865s-2.24-.856-2.24-2.574V172.58c0-2.368.748-3.555,2.256-3.555,1.722,0,2.689,1.2,2.909,3.579q1.929,14.964,8.39,23.407,12.911,16.921,34.536,16.913,11.617,0,18.719-6.79t7.1-18.119q0-12.607-8.065-20.054a35.086,35.086,0,0,0-8.232-5.988q-3.715-1.78-21.782-8.572-35.834-13.589-35.831-40.754,0-18.118,12.163-30.409t30.1-12.295q6.4,0,19.2,3.869a20.045,20.045,0,0,0,5.772.971q6.721,0,8.331-6.451c.63-2.146,1.595-3.234,2.875-3.234,1.5,0,2.242.866,2.242,2.59v15.49q-.328,7.748,1.614,24.851v.64a2.276,2.276,0,0,1-2.581,2.586q-1.942,0-4.845-9.769A37.6,37.6,0,0,0,759.39,82.6,31.694,31.694,0,0,0,738.9,75.43q-10.983,0-18.074,6.789a22.744,22.744,0,0,0-7.1,17.149q0,16.174,21.63,24.585,33.567,13.271,42.6,22a36.963,36.963,0,0,1,11.616,27.494"
                  transform="translate(270.166 -15.563)"
                />
              </g>
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(515.682 218.173)"
              >
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M412.678,227.121a68.709,68.709,0,0,0,.549,9.876h-3.466l-.553-5.978h-.156a14.182,14.182,0,0,1-12.137,6.93c-7.645,0-11.114-5.891-11.114-11.434,0-9.613,7.645-15.419,23.092-15.245v-1.129c0-4.07-.71-12.3-9.695-12.212a15.451,15.451,0,0,0-9.534,3.118l-1.184-3.032a19.548,19.548,0,0,1,11.112-3.55c10.956,0,13.084,9.008,13.084,16.372Zm-3.784-12.388c-8.275-.258-19.15,1.126-19.15,11.089,0,5.976,3.623,8.662,7.566,8.662a11.434,11.434,0,0,0,11.19-8.317,7.53,7.53,0,0,0,.394-2.511Z"
                  transform="translate(-385.802 -175.408)"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M443.9,181.33v51.975c0,3.032.078,6.67.316,9.615h-3.389l-.315-7.884h-.159a14.276,14.276,0,0,1-13.476,8.836c-9.378,0-16.315-8.576-16.315-20.962-.078-13.86,7.725-22.522,17.024-22.522,6.542,0,10.718,3.724,12.372,7.362h.158V181.33ZM440.116,217.8a16.017,16.017,0,0,0-.4-3.81c-1.259-5.544-5.752-10.136-11.9-10.136-8.274,0-13.475,7.883-13.475,18.625,0,9.354,4.255,17.93,13.24,17.93,5.438,0,10.559-3.984,12.135-10.828a11.81,11.81,0,0,0,.4-3.465Z"
                  transform="translate(-374.639 -181.33)"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M442.707,195.121l8.826,25.814c1.5,4.073,2.681,7.537,3.705,11.088h.157c1.026-3.465,2.445-7.1,3.862-11.088l8.986-25.814h3.94L456.813,236.7H453.5l-14.817-41.581Z"
                  transform="translate(-361.957 -175.112)"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M491.409,227.121a68.63,68.63,0,0,0,.55,9.876h-3.466l-.551-5.978h-.158a14.183,14.183,0,0,1-12.137,6.93c-7.644,0-11.114-5.891-11.114-11.434,0-9.613,7.645-15.419,23.093-15.245v-1.129c0-4.07-.71-12.3-9.7-12.212a15.45,15.45,0,0,0-9.534,3.118l-1.184-3.032a19.545,19.545,0,0,1,11.112-3.55c10.956,0,13.085,9.008,13.085,16.372Zm-3.784-12.388c-8.276-.258-19.151,1.126-19.151,11.089,0,5.976,3.623,8.662,7.567,8.662a11.434,11.434,0,0,0,11.189-8.317,7.525,7.525,0,0,0,.395-2.511Z"
                  transform="translate(-350.304 -175.408)"
                />
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M491.564,205.38c0-3.813-.078-6.671-.315-9.963h3.546l.238,7.45h.156a14.122,14.122,0,0,1,13-8.4c3.626,0,13.239,1.991,13.239,17.585V237h-3.781v-24.6c0-7.623-2.681-14.465-10.482-14.465-5.28,0-9.852,4.157-11.351,9.613a16.2,16.2,0,0,0-.471,3.64V237h-3.784Z"
                  transform="translate(-338.258 -175.408)"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M547.636,235.177a23.906,23.906,0,0,1-11.111,2.686c-10.877,0-18.129-8.749-18.129-21.222,0-13.254,8.2-22.175,19.468-22.175,4.648,0,8.509,1.471,9.927,2.6l-1.339,3.378a15.8,15.8,0,0,0-8.906-2.426c-10.166,0-15.365,8.489-15.365,18.28,0,10.912,6.224,18.017,14.972,18.017a19.478,19.478,0,0,0,9.457-2.426Z"
                  transform="translate(-326.018 -175.408)"
                />
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M546.886,191.625c-1.733,0-2.913-1.648-2.913-3.552s1.339-3.64,3.073-3.64,2.994,1.647,2.994,3.64c0,1.9-1.182,3.552-3.074,3.552Zm-1.733,49.9V199.94h3.785v41.581Z"
                  transform="translate(-314.486 -179.931)"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M556.231,205.38c0-3.813-.08-6.671-.314-9.963h3.546l.237,7.45h.157a14.121,14.121,0,0,1,13-8.4c3.624,0,13.239,1.991,13.239,17.585V237h-3.783v-24.6c0-7.623-2.68-14.465-10.481-14.465-5.28,0-9.853,4.157-11.35,9.613a16.1,16.1,0,0,0-.471,3.64V237h-3.784Z"
                  transform="translate(-309.1 -175.408)"
                />
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M616.4,230.934c0,10.655-2.049,15.939-5.2,19.23-3.466,3.637-8.589,4.939-12.844,4.939-4.335,0-9.143-1.215-12.06-3.64l1.339-3.29a19.053,19.053,0,0,0,11.271,3.463c7.959,0,13.714-4.5,13.714-16.8v-5.891h-.16A14,14,0,0,1,599.3,237c-9.538,0-16.236-9.1-16.236-20.443,0-14.468,8.669-22.09,17.023-22.09a13.315,13.315,0,0,1,12.766,7.97h.16l.235-7.018h3.469c-.238,3.118-.318,6.41-.318,10.742Zm-3.781-19.491a13.018,13.018,0,0,0-.475-4.073c-1.574-4.936-5.514-9.441-11.742-9.441-8.039,0-13.554,7.188-13.554,18.19,0,9.357,4.49,17.413,13.4,17.413,5.043,0,9.774-3.465,11.745-9.357a16.614,16.614,0,0,0,.63-4.416Z"
                  transform="translate(-296.859 -175.408)"
                />
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M628.767,191.625c-1.734,0-2.915-1.648-2.915-3.552s1.339-3.64,3.072-3.64,3,1.647,3,3.64c0,1.9-1.184,3.552-3.076,3.552Zm-1.734,49.9V199.94h3.784v41.581Z"
                  transform="translate(-277.568 -179.931)"
                />
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M638.113,205.38c0-3.813-.079-6.671-.315-9.963h3.546l.238,7.45h.156a14.122,14.122,0,0,1,13-8.4c3.623,0,13.239,1.991,13.239,17.585V237H664.2v-24.6c0-7.623-2.679-14.465-10.48-14.465-5.28,0-9.853,4.157-11.351,9.613a16.1,16.1,0,0,0-.471,3.64V237h-3.784Z"
                  transform="translate(-272.182 -175.408)"
                />
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M679.008,181.33h3.785v61.59h-3.785Z"
                  transform="translate(-253.601 -181.33)"
                />
                <path
                  id="Path_25"
                  data-name="Path 25"
                  d="M705.687,237.949c-9.773,0-17.339-8.4-17.339-21.395,0-14.207,8.511-22.09,17.891-22.09,10.481,0,17.575,8.662,17.575,21.395,0,15.42-9.693,22.09-18.047,22.09Zm.235-3.465c7.881,0,14.109-7.8,14.109-18.452,0-7.62-3.861-18.1-13.87-18.1-9.7,0-14.028,9.441-14.028,18.365,0,10.393,5.988,18.19,13.711,18.19Z"
                  transform="translate(-249.39 -175.408)"
                />
                <path
                  id="Path_26"
                  data-name="Path 26"
                  d="M719.842,195.121l8.826,25.814c1.5,4.073,2.68,7.537,3.7,11.088h.156c1.028-3.465,2.445-7.1,3.864-11.088l8.983-25.814h3.941L733.948,236.7h-3.309l-14.818-41.581Z"
                  transform="translate(-237.003 -175.112)"
                />
                <path
                  id="Path_27"
                  data-name="Path 27"
                  d="M745.48,215.773c0,13.255,6.619,18.539,14.423,18.539,5.515,0,8.354-1.212,10.481-2.34l.945,3.2c-1.418.868-5.279,2.686-11.9,2.686-10.876,0-17.654-8.662-17.654-20.875,0-13.86,7.252-22.522,17.024-22.522,12.372,0,14.739,12.818,14.739,18.8a11.561,11.561,0,0,1-.16,2.512Zm24.117-3.291c.078-5.8-2.129-14.552-11.27-14.552-8.356,0-11.9,8.142-12.611,14.552Z"
                  transform="translate(-225.3 -175.408)"
                />
              </g>
            </svg>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <div id="menu-header-menu" className="nav navbar-nav">
                <div className="menu-item nav-item navbar-nav">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="about_book"
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                  >
                    About the Book
                  </Link>
                </div>
                <div className="menu-item nav-item navbar-nav">
                  <Link
                    className="nav-link active"
                    to="endorsements"
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                  >
                    Endorsements
                  </Link>
                </div>
                <div className="menu-item nav-item navbar-nav">
                  <Link
                    className="nav-link active"
                    to="media"
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                  >
                    Media
                  </Link>
                </div>
                <div className="menu-item nav-item navbar-nav">
                  <Link
                    className="nav-link active"
                    to="subscribe"
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                  >
                    Order
                  </Link>
                </div>
                <div className="menu-item nav-item navbar-nav d-none">
                  <Link
                    className="nav-link active"
                    to="reader_review"
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                  >
                    {" Reader's Review "}
                  </Link>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};
export default Header;
