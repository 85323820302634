import React from "react";
import { Button } from "react-bootstrap";
import RecentArticles from "./recentArticles.json";

const RecentArticlesSection = () => {
  const Article = RecentArticles.articles;

  const [maxRange, setMaxRange] = React.useState(3);

  const loadMore = React.useCallback(() => {
    setMaxRange((prevRange) => prevRange + 3);
  }, []);

  return (
    <>
      <h1 className="color_Red mb-4">Articles</h1>
      <div className="row">
        {Article &&
          Article.slice(0, maxRange).map((list) => (
            <div className="col-md-4 p-0 mb-4">
              <div className="media-articlesecond">
                {list.publicationName && list.publicationDate ? (
                  <p className="mediaarticle-desc">
                    {list.publicationName} <br /> {list.publicationDate}
                  </p>
                ) : null}
                {list.publicationTitle ? (
                  <p className="mediaarticle-title">{list.publicationTitle}</p>
                ) : null}
                {list.publicationLink ? (
                  <p className="mediaarticle-info">
                    <a
                      href={list.publicationLink}
                      className="mediaarticle-readmore"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col">
          <div className="mt-2 mb-2 text-center">
            <Button className="btn article-loadmore mb-3" onClick={loadMore}>
              Load More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentArticlesSection;
