import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer className="">
      <div className="container">
        <div className="row pt-5">
          <div className="col-lg-9 col-sm-12 col-md-8">
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-md-6">
                <h5 className="uppercase mb-3">Institute</h5>
                <ul className="list-unstyled text-small">
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://heartfulness.org/education/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Education
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.heartfulnessinstitute.org/medical"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Continual Medical Education
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://heartfulness.org/yoga/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Yoga Teachers Training Certification
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://research.heartfulness.org/in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Heartfulness Research
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-6">
                <h5 className="uppercase mb-3">Quick Links</h5>
                <ul className="list-unstyled text-small">
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.daaji.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Daaji
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.sahajmarg.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sahaj Marg
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://kanha.sahajmarg.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Kanha Shanti Vanam
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://kanha.sahajmarg.org/green-kanha/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Green Kanha
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.srcm.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shri Ram Chandra Mission
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.spiritualityfoundation.org/en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spirituality Foundation
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-8">
                <h5 className="uppercase mb-3">Related Links</h5>
                <ul className="list-unstyled text-small">
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://hfnlife.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Publications
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://www.shriramchandramission.org//digitalstore/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Digital Store
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link-secondary text-decoration-none"
                      href="https://hfnlife.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HFN Life Online Store
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-4">
            <div className="row">
              <div className="col-12 col-md hfn-mag">
                <a
                  href="https://www.heartfulnessmagazine.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    className="mb-2 heartfulness_magazine-"
                    src="../images/HFNmag-NOV-2021-Cover.jpg"
                    alt=""
                    placeholder="blurred"
                  />
                  <p>Heartfulness Magazine</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-7 bold">
            Heartfulness practices with a certified trainer are always free,
            whether in person or online.
          </div>
          <div className="col-md-5">
            <div className="social-icons">
              <a
                href="https://www.facebook.com/practiceheartfulness/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/facebook.png"
                  alt="facebook"
                  placeholder="blurred"
                />
              </a>
              <a
                href="https://twitter.com/heartfulness"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/twitter.png"
                  alt="twitter"
                  placeholder="blurred"
                  style={{ marginLeft: "10px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/heartfulness/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/instagram.png"
                  alt="instagram"
                  placeholder="blurred"
                  style={{ marginLeft: "10px" }}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/youtube.png"
                  alt="youtube"
                  placeholder="blurred"
                  style={{ marginLeft: "10px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/heartfulness/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/linkedin.png"
                  alt="linkedin"
                  placeholder="blurred"
                  style={{ marginLeft: "10px" }}
                />
              </a>
              <a
                href="https://t.me/heartfulness"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/telegram.png"
                  alt="linkedin"
                  placeholder="blurred"
                  style={{ marginLeft: "10px" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>
              © Copyright 2021 Sahaj Marg Spirituality Foundation, All Rights
              Reserved.
            </p>
          </div>
          <div className="col-md-6">
            <p className="term-policy text-right">
              <a href="https://heartfulness.org/en/terms/">Terms of Use</a> and
              <a href="https://heartfulness.org/en/privacy-policy/">
                Privacy & Cookie Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
