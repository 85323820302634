/** @jsx jsx */
import { jsx } from "theme-ui";
import { inputStyles } from "../../../utils/fabricStyles";
import useSubForm from "../utils/useSubForm";
import config from "../assets/config";

const ESubscribe = () => {
  const initialValues = {
    ref: config.ref,
    event: config.event,
    pnr: config.pnr,
    name: config.name,
    email: "",
    reg_json: config.reg_json,
    communication_preference: config.communication_preference,
  };

  const { handleSubmit, inputs, errors, setInputData, instantValidation } =
    useSubForm(initialValues);

  return (
    <form className="row g-3">
      <div className="col-lg-8 col-md-7 col-sm-8">
        <label htmlFor="inputEmail">
          <input
            placeholder="Enter Your Email address"
            type="email"
            name="email"
            value={inputs.email}
            className="form-control"
            id="inputEmail"
            onBlur={() => instantValidation("email")}
            onChange={(e) => setInputData(e)}
            style={errors.email ? inputStyles.inputError : null}
          />
          {errors.email ? (
            <span className="text-danger">Invalid Email</span>
          ) : (
            ""
          )}
        </label>
      </div>
      <div className="col-lg-4 col-md-5 col-sm-4 text-center">
        <button
          type="submit"
          className="btn btn-danger mb-3"
          onClick={(e) => handleSubmit(e)}
        >
          Subscribe
        </button>
      </div>
    </form>
  );
};

export default ESubscribe;
