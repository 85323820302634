import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ESubscribe from "../components/ESubscribe";
import Layout from "../components/layout";
import Seo from "../components/seo";
import RecentArticlesSection from "../components/recentArticlesBlock";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Button className={className} onClick={onClick} {...style}>
      <StaticImage placeholder="blurred" alt="" src="../images/arrow.png" />
    </Button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Button className={className} onClick={onClick} {...style}>
      <StaticImage placeholder="blurred" alt="" src="../images/arrow.png" />
    </Button>
  );
}

const IndexPage = () => {
  const [location, setLocation] = useState("IN");

  const getData = async () => {
    fetch(
      "https://geolocation-db.com/json/297364b0-2bc6-11ec-a8a6-1fc54772a803"
    )
      .then((response) => response.json())
      .then((data) => setLocation(data.country_code));
  };

  useEffect(() => {
    getData();
  }, []);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout>
      <Seo title="Home" />
      <section className="intro-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-first order-md-last order-sm-last order-xs-last text-center">
              <StaticImage
                alt=""
                src="../images/intro_book_cover.png"
                placeholder="blurred"
                className="img-fluid intro-book-cover"
              />
            </div>
            <div className="col-lg-6 order-lg-last order-md-first order-sm-first order-xs-first">
              <div className="intro-content">
                <h1 className="title mb-3">The Authentic Yoga</h1>
                <p className="bold mb-4">
                  Guidebook for an Evolving Consciousness
                </p>
                <p className="uppercase pb-0 mb-0">By P.Y. Deshpande</p>
                <p className="mb-3">
                  A Heartfulness publication with additional content by Daaji
                </p>
                <p className="textlight">
                  Lately, Yoga has taken the world by storm, as more and more
                  people recognize the health benefits of this age-old science
                  of well-being.
                </p>
                <div className="text-left">
                  <a
                    className="btn pre-order mx-1"
                    href={
                      location === "US"
                        ? "https://beta.sahajmarg.org/onlinebookstore/catalog.do"
                        : "https://hfnlife.com/products/the-authentic-yoga?_pos=1&_sid=8a39fd57b&_ss=r"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order on Hfnlife
                  </a>
                  <a
                    className="btn pre-order mx-1"
                    href={
                      location === "US"
                        ? "https://www.amazon.com/gp/product/8195036589/ref=ox_sc_act_image_1?smid=A3TLS4PKJ7EQID&psc=1"
                        : "https://www.amazon.in/dp/8195374220/ref=cm_sw_r_apan_glt_fabc_P1HZ1QA3D1FTNQWDY3B9"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order on Amazon
                  </a>
                  <a className="btn holiday-gift mx-1 d-none" href="/">
                    Holiday Gifts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-book" id="about_book">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-8">
              <div className="about-content">
                <h1 className="title mb-2">About the Book</h1>
                <p className="mb-3">
                  P.Y. Deshpande’s The Authentic Yoga is a profound commentary
                  on the universal principles of Yoga, explaining the nature of
                  a meditative mind, the effect of meditation on consciousness,
                  how to realize our human potential, and what happens when we
                  succumb to likes and dislikes. His research is the result of
                  direct insight through yogic practice.
                </p>
                <p className="mb-3">
                  To complement this extraordinary understanding, in an
                  additional chapter Daaji introduces us to the next evolution
                  of yogic practices that allow us to experience the impact of
                  Yoga in our lives at all levels – physical, mental, emotional,
                  and spiritual.
                </p>
                <p className="mb-3">
                  If you are interested in improving your sense of well-being,
                  or if you are a seeker or practitioner of Yoga, this book will
                  find pride of place in your collection. With this higher
                  understanding, no longer do we have to see ourselves as
                  compartmentalized beings, with a physical body, a mind, a
                  heart, and a soul. <i>The Authentic Yoga</i> paves the way for
                  us to experience the true state of Yoga – the unification of
                  all these into one being we can rightfully call “human.”
                </p>
                <div className="row mt-3">
                  <div className="col-lg-2 col-md-3 text-center">
                    <StaticImage
                      src="../images/book-cover.png"
                      alt=""
                      placeholder="blurred"
                      className="img-fluid book-cover"
                    />
                  </div>
                  <div className="col-lg-10 col-md-9 py-2">
                    <p className="daaji-quote pr-3 px-3">
                      “May this exceptional book inspire you, the reader, to
                      also develop awareness of such sublime happiness, which is
                      possible through the practical approach of Yoga.”{" "}
                    </p>
                    <strong>-Daaji</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 text-center">
              <StaticImage
                src="../images/authentic_about_img.png"
                alt=""
                placeholder="blurred"
                className="img-fluid about-img"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="endorsements" id="endorsements">
        <div className="container">
          <h1 className="title mb-2">Endorsements</h1>
          <h5 className="sub-title mb-3">from Yoga experts</h5>
          <Slider className="endorsement-slider" {...settings}>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Vinay_Sahasrabuddhe.png"
                  alt="Vinay Sahasrabuddhe"
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  We at the Indian Council for Cultural Relations (ICCR) have
                  been proactively sharing the tradition of Yoga with people
                  from many other countries. P.Y. Deshpande wrote
                  <i> The Authentic Yoga</i> 75 years ago, and this new edition
                  will now reach the younger generations. I am confident that
                  reading this book will help us understand Yoga
                  comprehensively, which in turn will motivate us to practise
                  Yoga with great enthusiasm and strong commitment!
                </p>
                <p className="experts-info">
                  — Vinay Sahasrabuddhe, President, ICCR
                </p>
              </div>
            </div>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Dr-HR-Nagendra.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  <i>The Authentic Yoga</i> is a remarkable treatise on the
                  ancient science of well-being. P.Y. Deshpande unlocks the
                  meaning of the Sutras and brings them to life. In addition,
                  Daaji presents modern-day yogic practices for physical,
                  mental, emotional, and spiritual well-being. This publication
                  is a great contribution to the literary tradition of Yoga.
                </p>
                <p className="experts-info">
                  — Dr. H. R. Nagendra, Founder Chancellor, Swami Vivekananda
                  Yoga Anushashan Sansthanam (SVYASA deemed to be university)
                </p>
              </div>
            </div>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Rao.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  I highly recommend this book for research scholars, and
                  academic study in the fields of Yoga, the humanities,
                  psychology, and health, in particular the discipline of
                  neuroscience. Where science and Yoga meet, a new future can be
                  forged.
                </p>
                <p className="experts-info">
                  — Dr. Raghavendra Rao, Director, Central Council for Research,
                  Yoga and Naturopathy, Ministry of Ayush, India
                </p>
              </div>
            </div>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Gopi.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  As a sportsperson and coach, I have come to appreciate the
                  wonderful benefits Yoga has to offer for life and success.
                  Recently I read Deshpande’s <i>The Authentic Yoga</i>, a
                  Heartfulness publication, and am amazed at how much more I
                  have to discover. This book has opened my eyes.
                </p>
                <p className="experts-info">
                  — Mr. Gopichand, International Badminton Coach and National
                  Coach for the Indian Team.
                </p>
              </div>
            </div>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Rajesh.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  <i>The Authentic Yoga</i> by Shri P. Y. Deshpande is a
                  wonderful example of the continuously evolving science of Yoga
                  in modern times. Patanjali’s Yoga Sutras, which are in seed
                  form, are explained as a holistic system of human endeavour.
                  Also, Honourable Shri Daaji introduces us to the next
                  evolution of yogic practices, complementing Deshpande’s
                  philosophy. This book is a must-read for all serious seekers
                  and practitioners of Yoga.
                </p>
                <p className="experts-info">
                  — Honourable Vaidhya Shri Rajesh Kotecha, Secretary to the
                  Government of India, Ministry of Ayush.
                </p>
              </div>
            </div>
            <div>
              <div className="slider-tile">
                <StaticImage
                  src="../images/Hansaji-Jayadeva-Yogendra.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid slider-img"
                />
                <p className="experts-desc">
                  The Yoga Sutras shed light on every aspect of human
                  well-being, offering valuable lessons on the universal
                  principles of Yoga. <i>The Authentic Yoga</i> is a fresh
                  interpretation of these sutras. It is well-structured and
                  thematic, making it an easy read for everyone. P. Y. Deshpande
                  and Daaji have done a commendable job in bringing this work to
                  life. I have thoroughly enjoyed this wonderful book and am
                  certain you will find immense value in it too.
                </p>
                <p className="experts-info">
                  — Dr. Hansaji Yogendra, Director, The Yoga Institute
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="media" id="media">
        <div className="container">
          <h1 className="color_Red mb-4">
            Media - Launch of “The Authentic Yoga Book”
          </h1>
          <div className="row">
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/BringsYogaCloserToOneandAll.png"
                    alt="Duniya Khabar"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Duniya Khabar, Bhubaneshwar <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness Launches “The Authentic Yoga” Book, Brings Yoga
                  Closer To One and All
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW+3f5dh2LdSeBqXdMJ17EAa0UIce4hv1SI7mV0NaNiUnLr3x37TKfU8FhFaSz8SmQw=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/AY_bringsYogacloser.png"
                    alt="Dainik Adhikar"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Dainik Adhikar, Jaipur <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness Institute launches book Authentic Yoga and brings
                  Yoga closer
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW32eHe0oc5MGm4D1+u9Qo9F5qB6F9AuttzRR/U8ULEO2n6vSwbX1nh4xL+0Qt20wDQ=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/AY_HindustanExpress.png"
                    alt="Hindustan Express"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Hindustan Express, Jaipur <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness Institute launches book Authentic Yoga and brings
                  Yoga closer
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW27EN2foAarhM2N9RK5oaVixwBRM9FSRg96bJhkawGnbAtVr5NKNgDuy5lEfjFp/fA=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/AY_UdayToday.png"
                    alt="Uday Today"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Uday Today, Jaipur <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness Institute launches book Authentic Yoga and brings
                  Yoga closer
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW27EN2foAarhM2N9RK5oaVgD+wZt6dtJGgKRueJT4Lw56IL68Tt0fNksJvLTFQHl8A=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/AY_FineTimes.png"
                    alt="Fine Times"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Fine Times, Bhopal <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness Launches “The Authentic Yoga” Book, Brings Yoga
                  Closer To One and All
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW6PWH2FUsEd0dXsGjQYkUMsme21EMXzXUqOSMbp1up4JdPdiRWZL8P3CVFTdPTgPRQ=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-article">
                <div className="mediaArticle-img">
                  <StaticImage
                    src="../images/AY_Swadesh.png"
                    alt="Swadesh"
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </div>
                <p className="mediaarticle-desc">
                  Swadesh, Bhopal <br /> Jan 25, 2022
                </p>
                <p className="mediaarticle-title">
                  Heartfulness’ Launches “The Authentic Yoga” Book, Brings Yoga
                  Closer To One and All
                </p>
                <p className="mediaarticle-info">
                  <a
                    href="http://indiamediamonitor.in/ViewImg.aspx?sMv6Qtd4+2btHcDn352pW6PWH2FUsEd0dXsGjQYkUMu4Pw4bDHhExb+3mlAWVcfOIaFYOMGs+F9jjCdVFdac7g=="
                    className="mediaarticle-readmore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <RecentArticlesSection />
        </div>
      </div>
      <div className="media d-none" id="media">
        <div className="container">
          <h1 className="color_Red mb-4">Media</h1>
          <div className="row">
            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_751.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_752.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_753.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_755.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_756.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4 p-0">
              <div className="media-click">
                <a href="/">
                  <StaticImage
                    src="../images/Rectangle_754.png"
                    alt=""
                    placeholder="blurred"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="row d-none">
            <div className="col">
              <div className="container mt-2 mb-2 text-right">
                <StaticImage
                  src="../images/Next_Icon.png"
                  alt=""
                  placeholder="blurred"
                  className="img-fluid text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="yoga-subscribe-bg py-3" id="subscribe">
        <div className="container">
          <div className="jumborton">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12 col-md-7">
                <h1 className="color_Red mb-3 pt-5 mt-2">Order</h1>
                <p className="bold mb-5 sub-heading">Get the printed version</p>
                <ESubscribe />
                <hr className="my-4" />
                <p className="mt-4">
                  For all queries and interests to contribute with Heartfulness
                  Institute, please write to
                </p>
                <h2 className="color_Red">AY@heartfulness.org</h2>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-5 align-items-center">
                <StaticImage
                  className="mb-2"
                  src="../images/Book_Subscribe_Mockup.png"
                  alt=""
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

PrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IndexPage;
